<template>
    <footer>
      <section class="bg-mainColor text-center py-2">
        <ul class="space-y-2 md:space-y-0 md:flex md:justify-center md:space-x-6 px-2">
          <li class="bg-green-800 py-2 px-4 rounded-2xl flex items-center justify-center text-white">
            <i class="fa-regular fa-copyright"></i>&nbsp;&nbsp;2024 Five Loaves Food & Clothing. All rights reserved.
          </li>
          <li class="bg-green-800 py-2 px-4 rounded-2xl flex items-center justify-center text-white">
            <i class="fa-solid fa-heart"></i>&nbsp;&nbsp;This Institution is an Equal Opportunity Provider and TEFAP/USDA Food Provider.
          </li>
          <li class="bg-green-800 py-2 px-4 rounded-2xl flex items-center justify-center text-white">
            <a href="https://github.com/DeceitfulDragon/five-loaves-website" class="hover:text-gray-300">
              <i class="fa-brands fa-github"></i>&nbsp;&nbsp;Designed and hosted by DeceitfulDragon
            </a>
          </li>
        </ul>
      </section>
    </footer>
  </template>
  
  <script>
  export default {
    name: 'Footer',
  };
  </script>
  
  <style scoped>
  </style>